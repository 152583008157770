.web-container {
    margin: 0 auto;
}

.main-container {
    margin-left: 20px;
    margin-right: 20px;
}

.title-line {
    margin-top: 50px;
    margin-bottom: 50px;
}

.main-title {
    float: left;
    margin: 0 0 0 5px;
}

.score-text {
    float: right;
    font-weight: bold;
    margin: 15px 5px 0 0;
}

.how-to-play-block {
    float: left;
    line-height: 1.8;
    text-align: left;
}

.document-background-color {
    background-color: oldlace;
}