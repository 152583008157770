.components-group-horizontal {
    display: flex;
    align-content: stretch;
    flex-direction: row;
}

.button {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid black;
    margin-left: 5px;
    margin-right: 5px;
}